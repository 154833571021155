<template>
	<div id="app">
		<Header :tabs="tabs" :loading="loading" />
		<router-view :tabs="tabs" :loading="loading" />
		<Footer :tabs="tabs" :loading="loading" />
	</div>
</template>

<script>
import Footer from "./views/Footer.vue";
import Header from "./views/Header.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
	components: {
		Footer,
		Header,
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState({
			tabs: (state) => state.tabs.tabs,
		}),
	},
	methods: {
		featchTabs() {
			this.$store.dispatch("tabs/featchAll").then(() => {
				this.loading = true;
			});
		},
	},
	mounted() {
		this.featchTabs();
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: OleoScript;
	src: url("../public/Fonts/OleoScript-Regular.ttf");
}
@font-face {
	font-family: Montserrat;
	src: url("../public/Fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
	font-family: MontserratMed;
	src: url("../public/Fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
	font-family: MontserratBlack;
	src: url("../public/Fonts/Montserrat/Montserrat-Black.ttf");
}
@font-face {
	font-family: Cocon;
	src: url("../public/Fonts/Cocon® Next Arabic-Light.otf");
}
@font-face {
	font-family: CoconBold;
	src: url("../public/Fonts/Nizar Cocon Kurdish Bold.otf");
}
:root {
	--red-color: #f23041;
	--blue-color: #049dbf;
	--yellow-color: #f2cb05;
	--green-color: #a9bf04;
	--grey-color: #f2f2f2;
	--black-color: #0d0d0d;
	--pink-color: #f207a0;
}
.text-red {
	color: var(--red-color);
}
.text-blue {
	color: var(--blue-color);
}
.text-yellow {
	color: var(--yellow-color);
}
.text-green {
	color: var(--green-color);
}
.text-grey {
	color: var(--grey-color);
}
.text-pink {
	color: var(--pink-color);
}
.date {
	color: #999;
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: Cocon;
	font-size: 22px;
	direction: rtl;
}
@media only screen and (max-width: 768px) {
	* {
		font-size: 20px;
	}
}
a {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
	text-decoration: none;
}
a:hover {
	color: var(--yellow-color);
}
ul {
	margin: 0;
}
p {
	margin-bottom: 0;
}
.paragraph {
	line-height: 22px;
}
</style>
