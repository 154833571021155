<template>
	<div class="page">
		<loader
			spiner
			v-if="loading || loadingFilter"
			:width="'100px'"
			class="left mt-4"
			style="margin: 100px auto; display: flex; justify-content: center"
		></loader>
		<div class="left" v-else>
			<breadcrumbs
				class="mt-3 mb-5 breadcrumbs"
				:links="[
					{ name: 'الرئيسية', path: '/' },
					{ name: tab.name, path: `/all-products/${tab.id}`, active: true },
				]"
			></breadcrumbs>
			<div
				class="cata"
				ref="categoriesFilter"
				@wheel.prevent="scrollHorizontally($event)"
			>
				<ul class="cata-list" ref="cata">
					<li
						class="cata-list-item"
						v-for="section in tab.sections"
						:key="section.id"
						@click="changeSection(section.id)"
						:class="{ active: sectionId === section.id }"
					>
						{{ section.name }}
					</li>
				</ul>
			</div>
			<div class="level">
				<ul
					class="level-list show"
					ref="level"
					v-if="sectionFilters.length > 0"
				>
					<li
						class="level-list-item"
						@click="changeSectionFilter(filter.id)"
						v-for="filter in sectionFilters"
						:key="filter.id"
						:class="{ active: filterId === filter.id }"
					>
						{{ filter.name }}
					</li>
				</ul>
			</div>
			<div class="main-grid">
				<loader card class="col-4" v-if="products.length === 0"></loader>
				<product-card
					v-else
					:imageHeight="'300px'"
					:product="true"
					class="main-grid-item"
					v-for="product in filteredProducts"
					:key="product.id"
					:data="product"
				></product-card>
				<p
					v-if="filteredProducts.length === 0 && products.length !== 0"
					class="fs-3 text-center text-uppercase text-secondary my-5"
				>
					لا يوجد منتجات
				</p>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import RightSide from "../../components/RightSide.vue";
import { mapState } from "vuex";
import Loader from "@/components/loader.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
	props: ["routerSection"],
	components: {
		RightSide,
		ProductCard,
		Loader,
		Breadcrumbs,
	},
	data() {
		return {
			colors: {
				0: "var(--red-color)",
				1: "var(--green-color)",
				2: "var(--blue-color)",
				3: "var(--yellow-color)",
			},
			cataIndex: -1,
			section: -1,
			filter: -1,

			tab: {},
			loading: true,
			loadingFilter: true,

			sectionId: null,
			filterId: null,
		};
	},
	computed: {
		// filteredProducts() {
		// 	return this.products
		// 		.filter((c) => {
		// 			return c.tabs_ids.find((e) => e == this.$route.params.id);
		// 		})
		// 		.filter((c) => {
		// 			if (this.section === -1) return true;
		// 			return c.sections_ids.find((e) => e == this.section);
		// 		})
		// 		.filter((c) => {
		// 			if (this.filter === -1) return true;
		// 			return c.filter_id.find((e) => e == this.filter);
		// 		});
		// },
		filteredProducts() {
			return this.products
				.filter((c) => {
					return c.tabs_ids.find((e) => e == this.$route.params.id);
				})
				.filter((c) => {
					if (this.sectionId === null) return true;
					return c.sections_ids.find((e) => e == this.sectionId);
				})
				.filter((c) => {
					if (this.filterId === null) return true;
					return c.filter_id.find((e) => e == this.filterId);
				});
		},
		sectionFilters() {
			return this.filters.filter((e) => {
				if (this.sectionId === null) return false;
				return this.tab.sections
					.find((c) => c.id === this.sectionId)
					.filter_ids.includes(e.id);
			});
		},
		...mapState({
			products: (state) => state.products.products,
			filters: (state) => state.filters.filters,
		}),
	},
	watch: {
		"$route.params"() {
			this.filterId = null;
			this.routerSection
				? (this.sectionId = this.routerSection)
				: (this.sectionId = null);
			this.featchTab();
		},
	},
	methods: {
		// cataColors() {
		// 	this.$refs["cata"].childNodes.forEach((e, i) => {
		// 		e.style.background = this.colors[i % 4];
		// 	});
		// },
		// levelsArray() {
		// 	if (this.section == -1) return;
		// 	const levelsArray = [];
		// 	this.filters.forEach((e) => {
		// 		if (
		// 			this.tab.sections
		// 				.find((c) => c.id == this.section)
		// 				["filter_ids"].includes(e.id)
		// 		)
		// 			levelsArray.push(e);
		// 	});
		// 	return levelsArray;
		// },
		// changeLevel(id) {
		// 	if (!this.$refs["filteritem" + id]) return;
		// 	this.$refs.level.childNodes.forEach((e) => {
		// 		if (e !== this.$refs["filteritem" + id][0]) {
		// 			e.classList.remove("active");
		// 		}
		// 	});
		// 	this.$refs["filteritem" + id][0].classList.toggle("active");
		// 	this.filter == id ? (this.filter = -1) : (this.filter = id);
		// },
		// changeCata(id) {
		// 	if (!this.$refs["cataitem" + id]) return;
		// 	this.$refs.cata.childNodes.forEach((e) => {
		// 		if (e !== this.$refs["cataitem" + id][0]) {
		// 			e.classList.remove("active");
		// 		}
		// 	});
		// 	this.$refs["cataitem" + id][0].classList.toggle("active");
		// 	this.section == id ? (this.section = -1) : (this.section = id);
		// 	this.section == -1
		// 		? this.$refs["level"]?.classList.remove("show")
		// 		: this.$refs["level"]?.classList.add("show");
		// 	this.levelsArray();
		// },
		// featchFilter() {
		// 	this.loadingFilter = false;
		// 	this.$store
		// 		.dispatch("filters/featchAll")
		// 		.then((this.loadingFilter = true))
		// 		.finally(() => {
		// 			if (this.routerSection) {
		// 				this.changeCata(this.routerSection);
		// 				this.$refs["level"]?.classList.add("show");
		// 			}
		// 		});
		// },
		// featchTab() {
		// 	this.loading = false;
		// 	this.$store
		// 		.dispatch("tabs/featchById", { id: this.$route.params.id })
		// 		.then((data) => {
		// 			this.loading = true;
		// 			this.tab = data;
		// 			this.featchFilter();
		// 		})
		// 		.finally(() => {
		// 			this.cataColors();
		// 		});
		// },
		featchTab() {
			this.loading = true;
			return this.$store
				.dispatch("tabs/featchById", { id: this.$route.params.id })
				.then((data) => {
					this.tab = data;
				})
				.finally(() => {
					this.loading = false;
					if (this.routerSection) {
						this.sectionId = parseInt(this.routerSection);
					}
				});
		},
		changeSection(id) {
			this.sectionId !== id ? (this.sectionId = id) : (this.sectionId = null);
			this.filterId = null;
		},
		changeSectionFilter(id) {
			this.filterId !== id ? (this.filterId = id) : (this.filterId = null);
		},
		scrollHorizontally($event) {
			this.$refs.categoriesFilter.scrollLeft += $event.deltaY * 0.5;
		},
	},
	mounted() {
		Promise.all([
			this.$store.dispatch("filters/featchAll"),
			this.featchTab(),
		]).finally(() => {
			this.loadingFilter = false;
		});
	},
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
	margin-right: 300px;
	@media only screen and (max-width: 992px) {
		margin-right: 0;
	}
}
.page {
	max-width: 1450px;
	margin: auto;
	margin-top: 130px;
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
	@media only screen and (max-width: 992px) {
		& {
			display: block;
			margin-top: 190px;
		}
	}
	.left {
		width: 66%;
		overflow: hidden;
		@media only screen and (max-width: 992px) {
			& {
				width: 100%;
			}
		}
		::-webkit-scrollbar {
			display: none;
		}
		.cata {
			overflow-x: auto;
			margin-bottom: 12px;
			padding: 5px;
			&-list {
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0;
				&-item {
					position: relative;
					color: white;
					font-family: CoconBold;
					text-transform: capitalize;
					padding: 5px 15px;
					font-size: 16px;
					border-radius: 10px;
					transition: 300ms;
					opacity: 0.3;
					white-space: nowrap;
					box-shadow: 0 0 4px #999;
					cursor: pointer;
					&:nth-of-type(1),
					&:nth-of-type(5) {
						background: var(--red-color);
					}
					&:nth-of-type(2),
					&:nth-of-type(6) {
						background: var(--green-color);
					}
					&:nth-of-type(3),
					&:nth-of-type(7) {
						background: var(--blue-color);
					}
					&:nth-of-type(4),
					&:nth-of-type(8) {
						background: var(--yellow-color);
					}
					&:not(:last-child) {
						margin-left: 0.3rem;
					}
				}
				&-item.active {
					opacity: 1;
					padding-left: 7px;
					padding-right: 23px;
					box-shadow: 0 0 4px #333;
				}
				&-item:after {
					content: "x";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 6px;

					transition: all 0.4s;
					visibility: visible;
					opacity: 0;
				}
				&-item.active:after {
					visibility: visible;
					opacity: 1;
				}
			}
		}
		.level {
			overflow: auto;
			margin-right: 5px;
			margin-bottom: 16px;
			&-list {
				width: max-content;
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				margin-bottom: 10px;
				transition: 200ms;
				padding: 0;
				&-item {
					position: relative;
					max-height: 0;
					padding: 0;
					width: 0;
					display: flex;
					align-items: center;
					font-size: 16px;
					font-family: CoconBold;
					background: transparent;
					border: 1px solid #ffff;
					border-radius: 20px;
					overflow: hidden;
					cursor: pointer;
					span {
						display: inline-block;
						font-family: CoconBold;
						font-size: 12px;
						margin-left: 3px;
					}
					&:not(:last-child) {
						margin-left: 0.2rem;
					}
				}
				&-item::after {
					content: "\00D7";
					font-size: 20px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 9px;
					display: none;
				}
				&-item.active {
					background: white !important;
					padding-left: 17px !important;
					padding-right: 23px !important;
					border: 1px solid #ccc !important;
				}
				&-item.active::after {
					display: block;
				}
			}
			&-list.show {
				.level-list-item {
					max-height: 100px;
					width: auto;
					padding: 2px 20px;
					overflow: visible;
					background: #d9d9d9;
					transition: 200ms;
				}
			}
		}
	}
}
.main-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	> a {
		width: 18vw;
		height: calc(18vw + 100px);
		margin-bottom: 33px;
	}
	@media (min-width: 1500px) {
		> a {
			max-width: 270px;
			max-height: 370px;
		}
	}
	@media (max-width: 992px) {
		> a {
			width: 30vw;
			height: calc(30vw + 100px);
		}
	}
	@media (max-width: 768px) {
		> a {
			width: 44vw;
			height: calc(44vw + 100px);
		}
	}
	@media (max-width: 578px) {
		justify-content: center;
		> a {
			width: 85vw;
			height: calc(85vw + 100px);
		}
	}
}
</style>