<template>
	<div class="page">
		<div class="uper">
			<div class="up">
				<div class="up-image" v-if="loadingSlide">
					<div></div>
					<img
						v-if="showImage"
						:src="slider[backgroundImageIndex].image"
						alt=""
						ref="coverImage"
					/>
				</div>
				<div class="up-slider" ref="slider" v-if="loadingSlide">
					<div
						id="myCarousel"
						class="carousel slide"
						data-bs-ride="carousel"
						ref="slide"
						data-bs-interval="3000"
					>
						<div class="carousel-indicators">
							<button
								type="button"
								data-bs-target="#myCarousel"
								:data-bs-slide-to="index"
								aria-current="true"
								:aria-label="'Slide' + index"
								v-for="(slide, index) in slider"
								:key="slide.id"
							></button>
						</div>
						<div class="carousel-inner">
							<div
								class="carousel-item"
								v-for="slide in slider"
								:key="slide.id"
							>
								<a :href="slide.link">
									{{
										slide.description.length > 30
											? slide.description.slice(0, 30) + "..."
											: slide.description
									}}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="uper-navbar">
				<router-link :to="{ name: 'all-products', params: { id: 12 } }">
					<div class="uper-navbar-item" style="background: var(--blue-color)">
						منتجات مجانية
					</div>
				</router-link>
				<router-link :to="{ name: 'all-products', params: { id: 13 } }">
					<div class="uper-navbar-item" style="background: var(--pink-color)">
						حِزم منتجات
					</div>
				</router-link>
				<router-link :to="{ name: 'all-products', params: { id: 14 } }">
					<div class="uper-navbar-item" style="background: var(--yellow-color)">
						خطط دراسية
					</div>
				</router-link>
				<router-link to="all-news">
					<div class="uper-navbar-item" style="background: var(--green-color)">
						مدوّنة
					</div>
				</router-link>
			</div>
		</div>
		<loader
			spiner
			:width="'100px'"
			v-if="!loading"
			style="
				height: 30vh;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 100px;
			"
		></loader>
		<div class="main d-lg-flex d-md-block d-block" v-else>
			<div class="main-left">
				<div class="search d-lg-none d-block mb-5">
					<p class="text-yellow mb-3">تبحث عن شيء؟</p>

					<form class="main-left-form" @submit.prevent="submit">
						<input
							type="text"
							class="main-left-form-input"
							placeholder="بحث"
							v-model="search"
						/>
						<button class="main-left-form-btn">
							<i class="fa fa-search"></i>
						</button>
					</form>
				</div>
				<!-- <section
					:style="
						allVideoText ? { height: 'auto' } : { height: section1Height }
					"
					ref="section1"
					class="mb-5"
				> -->
				<section ref="section1" class="mb-5 section1">
					<p class="text-capitalize text-red">شاهد الفيديو</p>
					<p class="text-capitalize title mb-2">{{ video[0].title }}</p>
					<div class="main-left-video mb-3">
						<iframe
							width="100%"
							height="100%"
							:src="
								'https://www.youtube.com/embed/' +
								findYoutubeVideoId(video[0].video_link)
							"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
					<p
						class="paragraph video-description"
						style="text-align: justify"
						ref="video-description"
						:style="allVideoText ? { height: 'auto' } : {}"
					>
						{{ video[0].description }}
						<span
							class="fw-light text-red video-read-more"
							ref="video-read-more"
							@click="
								allVideoText = !allVideoText;
								overFlowInVideoText = !overFlowInVideoText;
							"
							v-if="overFlowInVideoText"
							>اقراء المزيد...</span
						>
					</p>
				</section>
				<section class="section2 mb-5">
					<p class="text-uppercase title mb-3">أحدث المنتجات</p>
					<div
						class="related-arrow related-arrow-left d-none"
						@click="moveProductsLeft"
					>
						<i class="fa fa-angle-left"></i>
					</div>
					<div class="related">
						<product-card
							:img="true"
							:imageHeight="'250px'"
							v-for="product in arrLatestProducts"
							:key="product.id"
							:data="product"
						></product-card>
					</div>
					<div
						class="related-arrow related-arrow-right d-none"
						@click="moveProductsRight"
					>
						<i class="fa fa-angle-right"></i>
					</div>
				</section>
				<section v-if="loadingNews">
					<p class="text-uppercase title mb-3">الأحدث من المدونة</p>
					<div
						class="related-news"
						:class="{ 'justify-content-start': news.length < 3 }"
					>
						<article-card
							v-for="(news, i) in news.slice(0, 2)"
							:key="news.id"
							:data="news"
							:class="{ 'd-md-block d-none': i === 3 }"
						></article-card>
					</div>
				</section>
			</div>
			<right-side
				@height="heightFromChild"
				:products="products.filter((c) => !c.is_featured)"
			></right-side>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ProductCard from "../../components/ProductCard.vue";
import ArticleCard from "../../components/ArticleCard.vue";
import RightSide from "../../components/RightSide.vue";
import loader from "../../components/loader.vue";
import moment from "moment";

export default {
	props: ["tabs"],
	components: {
		ProductCard,
		ArticleCard,
		RightSide,
		loader,
	},
	data() {
		return {
			section1Height: "auto",
			allVideoText: false,
			overFlowInVideoText: false,
			loading: false,
			loadingNews: false,
			loadingSlide: false,
			thisProducts: [],
			arrLatestProducts: [],
			productNum: 0,
			carouselButtonId: 0,
			backgroundImageIndex: 0,
			showImage: true,
			search: null,
		};
	},
	methods: {
		latestArticleDate() {
			return moment(this.news[0].updated_at).format("MMM DD YYYY");
		},
		heightFromChild(x) {
			if (window.innerWidth < 992) {
				this.section1Height = "auto";
			} else if (this.$refs.section1.clientHeight - 30 < x[0])
				this.section1Height = x[0] + "px";
		},
		featch() {
			Promise.all([
				this.$store.dispatch("products/featchAll"),
				this.$store.dispatch("slider/featchAll"),
				this.$store.dispatch("video/featchAll"),
			])
				.then((data) => {
					this.loadingSlide = true;
					setTimeout(() => {
						this.loading = true;
						setTimeout(() => {
							this.overFlowInVideoText =
								this.$refs["video-description"]?.scrollHeight >
								this.$refs["video-description"]?.clientHeight;
						}, 1);
					}, 2000);
					this.thisProducts = data;
				})
				.finally(() => {
					document.querySelector(".carousel-item").classList.add("active");
					document
						.querySelector(".carousel-indicators button")
						.classList.add("active");
					this.latestProducts();
					window.addEventListener("resize", () => {
						this.latestProducts();
					});
					this.$refs.slide.addEventListener("slide.bs.carousel", (a) => {
						this.backgroundImageIndex = a.to;
						this.$refs.coverImage.style.opacity = "0";
						this.$refs.coverImage.style.transition = "";
						this.showImage = false;
						setTimeout(() => {
							this.showImage = true;
						}, 50);
						setTimeout(() => {
							this.$refs.coverImage.style.transition = "all 1s";
							this.$refs.coverImage.style.opacity = "1";
						}, 150);
					});
				});
		},
		moveProductsLeft() {
			if (this.productNum > 0) {
				this.arrLatestProducts = this.products.slice(
					--this.productNum,
					this.productNum + 1
				);
			}
		},
		moveProductsRight() {
			if (this.productNum < Math.min(this.products.length - 1, 8)) {
				this.arrLatestProducts = this.products.slice(
					++this.productNum,
					this.productNum + 1
				);
			}
		},
		latestProducts() {
			if (window.innerWidth > 992) {
				this.arrLatestProducts = this.products.slice(0, 9);
			} else if (window.innerWidth > 576)
				this.arrLatestProducts = this.products.slice(0, 6);
			else this.arrLatestProducts = this.products.slice(0, 1);
		},
		findYoutubeVideoId(videoLink) {
			if (videoLink.indexOf("=") !== -1) {
				return videoLink.slice(videoLink.indexOf("=") + 1);
			} else {
				return videoLink.slice(videoLink.indexOf("be/") + 3);
			}
		},
		submit() {
			this.$router.push({ name: "search", params: { id: this.search } });
		},
	},
	watch: {
		news() {
			this.loadingNews = true;
		},
	},
	computed: {
		screen() {
			return window.innerWidth;
		},
		...mapState({
			products: (state) => state.products.products,
			news: (state) => state.news.news,
			slider: (state) => state.slider.slider,
			video: (state) => state.video.video,
		}),
	},
	mounted() {
		window.addEventListener("scroll", () => {
			if (this.$refs["slider"]) {
				if (document.documentElement.scrollTop > 50) {
					this.$refs["slider"].style.display = "none";
				} else {
					this.$refs["slider"].style.display = "block";
				}
			}
		});

		this.featch();
	},
};
</script>

<style lang="scss" scoped>
.section1 {
	min-height: 760px;
	max-height: 860px;
	height: 56vw;
	@media (max-width: 992px) {
		height: auto;
		max-height: unset;
		min-height: unset;
	}
}
.uper {
	background: #f5f5f5;
	position: relative;
	margin-bottom: 50px;
	.up {
		background: #0008;
		height: 500px;
		position: relative;
		&-image {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			img,
			div {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			div {
				width: 100%;
				height: 100%;
				z-index: 1;
				background: #0008;
			}
		}
		&-slider {
			direction: rtl;
			position: absolute;
			top: 280px;
			transform: translateY(-50%);
			right: 5%;
			color: white;
			max-width: 25%;
			z-index: 3;
			@media only screen and (max-width: 992px) {
				right: 50%;
				top: 230px;
				transform: translateX(50%);
				min-width: 330px;
			}
			@media only screen and (max-width: 330px) {
				right: 0;
				left: 0;
				min-width: unset;
				transform: unset;
			}
		}
	}
	&-navbar {
		max-width: 1100px;
		padding: 50px 0;
		margin: auto;
		@media only screen and (max-width: 992px) {
			& {
				right: 5%;
				left: 5%;
				width: 90%;
			}
		}
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		@media only screen and(max-width:768px) {
			& {
				grid-template-columns: 1fr 1fr;
			}
		}
		cursor: pointer;
		&-item {
			border-radius: 50%;
			width: 13vw;
			height: 13vw;
			min-width: 170px;
			min-height: 170px;
			max-width: 200px;
			max-height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 2rem;
			font-family: coconBold;
			text-transform: capitalize;
			text-align: center;
			margin: auto;
			padding: 30px;
			transition: 200ms;
			scale: 0;
			animation: navItemEnter 500ms cubic-bezier(0.57, 0.74, 0.8, 1.33) 1s;
			animation-fill-mode: forwards;
			@keyframes navItemEnter {
				from {
					scale: 0;
				}
				to {
					scale: 1;
				}
			}
			@media only screen and(max-width:992px) {
				& {
					min-width: 150px;
					min-height: 150px;
					font-size: 28px;
				}
			}
			@media screen and (max-width: 768px) {
				& {
					min-width: 200px;
					min-height: 200px;
					margin-bottom: 40px;
					font-size: 30px;
				}
			}
			@media only screen and(max-width:500px) {
				& {
					min-width: unset;
					min-height: unset;
					width: 40vw;
					height: 40vw;
					font-size: 26px;
				}
			}
			position: relative;
			&::after {
				content: "";
				position: absolute;
				width: 112%;
				height: 112%;
				background: transparent;
				border-radius: 50%;
				right: -6%;
				top: -6%;
			}
		}
		& a:nth-child(1) &-item::after {
			border: 3px solid var(--blue-color);
		}
		& a:nth-child(2) &-item::after {
			border: 3px solid var(--pink-color);
		}
		& a:nth-child(3) &-item::after {
			border: 3px solid var(--yellow-color);
		}
		& a:nth-child(4) &-item::after {
			border: 3px solid var(--green-color);
		}
		&-item:hover {
			box-shadow: 0 0 15px #333;
			font-size: 2.2rem;
			@media only screen and(max-width:992px) {
				& {
					font-size: 1.6rem;
				}
			}
		}
	}
}
.main {
	max-width: 1450px;
	margin: auto;
	@media only screen and(max-width:992px) {
		& {
			margin: 0 10px;
		}
	}
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	&-left {
		width: 66%;
		@media only screen and(max-width:992px) {
			& {
				width: 100%;
			}
		}
		&-video {
			height: 480px;
			overflow: hidden;
			border-radius: 8px;
			@media only screen and(max-width:576px) {
				height: 320px;
			}
		}
		&-image {
			border-radius: 40px;
			height: 400px;
			background-image: linear-gradient(45deg, #eee, #888, #eee 150%);
			background-position-y: top;
			background-position-x: left;
			background-repeat: no-repeat;
			margin: 10px 0;
			@media only screen and(max-width:400px) {
				& {
					height: 300px;
				}
			}
		}
		&-form {
			position: relative;
			height: 50px;
			border: 1px solid grey;
			border-radius: 30px;
			max-width: 400px;
			&-input {
				border-radius: 30px;
				min-width: 0;
				height: 100%;
				width: 85%;
				outline: none;
				padding: 10px 20px;
				padding-left: 0;
				color: grey;
				border: none;
			}
			&-btn {
				position: absolute;
				left: -1px;
				top: -1px;
				bottom: -1px;
				min-width: 50px;
				border: none;
				background: var(--yellow-color);
				color: white;
				font-size: 24px;
				border-radius: 30px;
			}
		}
	}
}
.video-description {
	height: 154px;
	overflow: hidden;
	position: relative;
	@media (max-width: 992px) {
		height: auto;
	}
}
.video-read-more {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	font-size: 14px;
	cursor: pointer;
}
.related {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	> a {
		width: 18vw;
		height: calc(18vw + 100px);
		margin-bottom: 33px;
	}
	@media (min-width: 1500px) {
		> a {
			max-width: 270px;
			max-height: 370px;
		}
	}
	@media (max-width: 992px) {
		> a {
			width: 30vw;
			height: calc(30vw + 100px);
		}
	}
	@media (max-width: 768px) {
		> a {
			width: 44vw;
			height: calc(44vw + 100px);
		}
	}
	@media (max-width: 578px) {
		justify-content: center;
		> a {
			width: 85vw;
			height: calc(85vw + 100px);
		}
	}
	@media only screen and(max-width:576px) {
		&-arrow {
			display: flex !important;
			justify-content: center;
			align-items: center;
			color: white;
			width: 40px;
			height: 40px;
			box-shadow: 0 1px 3px #333;
			background: var(--red-color);
			border-radius: 50%;
			position: absolute;
			top: 50%;
			z-index: 2;
			padding: 4px;
			transition: all 0.2s;
			transform: translateY(-50%);
			&:active {
				transform: translateY(-50%) scale(0.9);
			}
			&-left {
				left: -25px;
			}
			&-right {
				right: -25px;
			}
		}
	}
}
.related-news {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	> a {
		width: 27vw;
		height: calc(27vw + 150px);
		margin-bottom: 20px;
	}
	@media (min-width: 1500px) {
		> a {
			max-width: 400px;
			max-height: 550px;
		}
	}
	@media (max-width: 992px) {
		> a {
			width: 45vw;
			height: calc(45vw + 150px);
		}
	}
	@media (max-width: 768px) {
		> a {
			width: 44vw;
			height: calc(44vw + 150px);
		}
	}
	@media (max-width: 578px) {
		justify-content: center;
		> a {
			width: 85vw;
			height: calc(85vw + 150px);
		}
	}
}
.title {
	font-size: 1.6rem;
	font-family: coconBold;
}
.paragraph {
	font-size: 1rem;
	overflow: hidden;
}
.section2 {
	position: relative;
}

/** carousel */
.carousel-item {
	font-size: 50px;
	font-family: coconBold;
	line-height: 70px;
	height: 140px;
	overflow: hidden;
	@media only screen and (max-width: 1200px) {
		& {
			font-size: 42px;
			line-height: 50px;
			height: 100px;
		}
	}
	@media only screen and(max-width:768px) {
		& {
			font-size: 38px;
			line-height: 45px;
			height: 90px;
		}
	}
}
.carousel-item a:hover {
	color: #ddd;
}
.carousel-indicators {
	position: absolute;
	bottom: -50px;
	right: 0;
	left: unset;
	margin: 0;
}
.carousel-indicators > button {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 1px solid white;
	background: transparent;
}
.carousel-indicators > button.active {
	background: white;
}
</style>