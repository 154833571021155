import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        slider: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/sliders')
                .then((response) => {
                    commit(types.SLIDERS, response.data);
                    return response.data
                })
        }
    },

    mutations: {
        [types.SLIDERS](state, slider) {
            state.slider = slider
        }
    }
}