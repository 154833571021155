<template>
	<router-link :to="{ name: 'news', params: { id: data.id } }" v-if="data">
		<div
			class="my-card"
			:class="horizontal ? 'd-sm-flex d-block' : 'transtion'"
		>
			<div class="image">
				<img :src="data.primary_image" alt="" />
			</div>
			<div class="data">
				<div class="date mt-1">
					<i class="fa fa-calendar"></i>
					{{ date() }}
				</div>
				<p class="fw-b text-blue card-title my-1">
					{{
						data.title.length > 30
							? data.title.slice(0, 30) + "..."
							: data.title
					}}
				</p>
				<p class="fw-b card-summary my-1">
					{{
						data.summary.length > 80
							? data.summary.slice(0, 80) + "..."
							: data.summary
					}}
				</p>
				<p class="text-yellow fw-bold read-more">
					قراءة المزيد <i class="fa fa-angle-double-left"></i>
				</p>
			</div>
		</div>
	</router-link>
</template>

<script>
import moment from "moment";
export default {
	props: {
		horizontal: { default: false },
		data: null,
	},
	methods: {
		date() {
			return moment(this.data.updated_at).format("MMM DD YYYY");
		},
	},
};
</script>

<style lang="scss" scoped>
.my-card {
	transition: 300ms;
	cursor: pointer;
	.image {
		border: 1px solid #ccc;
		border-radius: 30px;
		overflow: hidden;
		transition: 300ms;
		min-width: 170px;
		max-width: 170px;
		height: 170px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: 200ms;
		}
		@media (max-width: 576px) {
			height: 85vw;
			max-width: 85vw;
			min-width: 85vw;
		}
	}
	.data {
		padding-right: 10px;
		.date {
			font-size: 14px;
		}
		.image:hover {
			box-shadow: 0 0 10px #777;
		}
		.card-title {
			font-size: 16px;
			font-family: CoconBold;
		}
		.card-summary {
			font-size: 14px;
			color: #333;
		}
	}
}
.transtion {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	align-items: flex-start;
	.image {
		margin: auto;
		max-width: unset;
		min-width: unset;
		height: auto;
	}
	.data {
		max-height: 150px;
		min-height: 150px;
		padding: 0;
	}
}
.my-card:hover {
	scale: 1.03;
}
.my-card:hover .image {
	box-shadow: 0 0 15px #0006;
}
.read-more {
	font-size: 0.8rem;
	transition: 200ms;
	* {
		font-size: inherit;
	}
}
.read-more:hover {
	transform: translateX(-5px);
}
</style>