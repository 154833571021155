<template>
	<div class="page">
		<loader
			spiner
			:width="'100px'"
			class="left mt-5"
			style="
				height: 30vh;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 100px auto;
			"
			v-if="loading"
		></loader>
		<div class="left mb-5" v-else>
			<div class="d-lg-none d-block mb-5">
				<p class="text-yellow mb-3">تبحث عن شيء?</p>

				<form class="search" @submit.prevent="submit">
					<input
						type="text"
						class="search-input"
						placeholder="البحث.."
						v-model="search"
					/>
					<button class="search-btn">
						<i class="fa fa-search"></i>
					</button>
				</form>
			</div>
			<div style="overflow-x: auto" class="mb-3">
				<div style="width: max-content">
					<div
						class="title my-3"
						style="background: var(--red-color)"
						@click="typeOfSearch = 0"
						:class="{ active: typeOfSearch === 0 }"
					>
						المنتجات
					</div>
					<div
						class="title my-3"
						style="background: var(--green-color)"
						@click="typeOfSearch = 1"
						:class="{ active: typeOfSearch === 1 }"
					>
						المدونة
					</div>
					<div
						class="title my-3 me-0"
						style="background: var(--blue-color)"
						@click="typeOfSearch = 2"
						:class="{ active: typeOfSearch === 2 }"
					>
						المعرض
					</div>
				</div>
			</div>
			<div class="main-grid" v-if="typeOfSearch === 0">
				<product-card
					class="main-grid-item"
					v-for="product in products.article"
					:key="product.id"
					:data="product"
				></product-card>
				<p
					v-if="products.article.length === 0 && !loading"
					class="fs-3 text-center text-uppercase text-secondary my-5"
				>
					no products
				</p>
			</div>

			<div class="main-grid-article" v-if="typeOfSearch === 1">
				<article-card
					class="main-grid-item"
					v-for="article in products.blog"
					:key="article.id"
					:data="article"
				></article-card>
				<p
					v-if="products.blog.length === 0 && !loading"
					class="fs-3 text-center text-uppercase text-secondary my-5"
				>
					no articles
				</p>
			</div>

			<div class="main-album main-grid" v-if="typeOfSearch === 2">
				<album-card
					class="main-album-item"
					v-for="album in products.albums"
					:key="album.id"
					:data="album"
				></album-card>
				<p
					v-if="products.albums.length === 0 && !loading"
					class="fs-3 text-center text-uppercase text-secondary my-5"
				>
					no albums
				</p>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import ProductCard from "../../components/ProductCard.vue";
import ArticleCard from "../../components/ArticleCard.vue";
import AlbumCard from "../../components/AlbumCard.vue";
import Loader from "../../components/loader.vue";
import RightSide from "../../components/RightSide.vue";
export default {
	components: { ProductCard, ArticleCard, Loader, RightSide, AlbumCard },
	data() {
		return {
			products: { article: [], blog: [] },
			loading: true,

			search: null,

			typeOfSearch: 0,
		};
	},
	methods: {
		submit() {
			this.$router.push({ name: "search", params: { id: this.search } });
		},
		featchProducts() {
			this.loading = true;
			(this.products = { article: [], blog: [] }),
				this.$store
					.dispatch("products/featchBySearch", {
						search: this.$route.params.id,
					})
					.then((data) => {
						this.products = data;
						this.loading = false;
					});
		},
	},
	watch: {
		"$route.params"() {
			this.featchProducts();
		},
	},
	mounted() {
		this.featchProducts();
	},
};
</script>

<style lang="scss" scoped>
.page {
	max-width: 1340px;
	margin: auto;
	margin-top: 220px;
	@media only screen and (min-width: 1400px) {
		& {
			margin-top: 255px;
		}
	}
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
	@media only screen and (max-width: 992px) {
		& {
			display: block;
		}
	}
	.left {
		flex: 2;
		overflow: hidden;
		@media only screen and (max-width: 992px) {
			& {
				width: 100%;
			}
		}
		.main-grid {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			> a {
				width: 18vw;
				height: calc(18vw + 100px);
				margin-bottom: 20px;
			}
			@media (min-width: 1500px) {
				> a {
					max-width: 270px;
					max-height: 370px;
				}
			}
			@media (max-width: 992px) {
				> a {
					width: 30vw;
					height: calc(30vw + 100px);
				}
			}
			@media (max-width: 768px) {
				> a {
					width: 44vw;
					height: calc(44vw + 100px);
				}
			}
			@media (max-width: 578px) {
				justify-content: center;
				> a {
					width: 85vw;
					height: calc(85vw + 100px);
				}
			}
		}
		.main-grid-article {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			> a {
				width: 18vw;
				height: calc(18vw + 150px);
				margin-bottom: 20px;
			}
			@media (min-width: 1500px) {
				> a {
					max-width: 270px;
					max-height: 420px;
				}
			}
			@media (max-width: 992px) {
				> a {
					width: 30vw;
					height: calc(30vw + 150px);
				}
			}
			@media (max-width: 768px) {
				> a {
					width: 44vw;
					height: calc(44vw + 150px);
				}
			}
			@media (max-width: 578px) {
				justify-content: center;
				> a {
					width: 85vw;
					height: calc(85vw + 150px);
				}
			}
		}
	}
}
.search {
	position: relative;
	height: 50px;
	border: 1px solid grey;
	border-radius: 30px;
	max-width: 400px;
	&-input {
		border-radius: 30px;
		min-width: 0;
		height: 100%;
		width: 85%;
		outline: none;
		padding: 10px 20px;
		padding-left: 0;
		color: grey;
		border: none;
	}
	&-btn {
		position: absolute;
		left: -1px;
		top: -1px;
		bottom: -1px;
		min-width: 50px;
		border: none;
		background: var(--yellow-color);
		color: white;
		font-size: 24px;
		border-radius: 30px;
	}
}
.title {
	display: inline-block;
	position: relative;
	color: white;
	font-family: MontserratBold;
	text-transform: capitalize;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 10px;
	transition: 300ms;
	opacity: 0.3;
	white-space: nowrap;
	box-shadow: 0 0 4px #999;
	margin-left: 10px;
	cursor: pointer;
}
.title::after {
	content: "\00D7";
	font-size: 1rem;
	position: absolute;
	top: 1px;
	bottom: 0;
	left: 6px;
	display: none;
}
.title.active {
	opacity: 1;
	padding-right: 7px;
	padding-left: 23px;
	box-shadow: 0 0 4px #333;
}
.title.active::after {
	display: block;
}
</style>