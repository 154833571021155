<template>
	<div class="page">
		<loader
			spiner
			v-if="!loading"
			:width="'100px'"
			class="left mt-5"
			style="
				margin: 100px auto;
				display: flex;
				justify-content: center;
				width: 100%;
			"
		>
		</loader>
		<div class="left mb-5" v-else>
			<div v-for="about in aboutUs" :key="about.id">
				<div class="fs-2 fw-bold text-blue mt-3 text-capitalize">من نحن</div>
				<div v-html="about.Value"></div>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import loader from "@/components/loader.vue";
import { mapState } from "vuex";
import RightSide from "@/components/RightSide.vue";

export default {
	components: { loader, RightSide },
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState({
			aboutUs: (state) => state.about.about,
		}),
	},
	mounted() {
		this.$store
			.dispatch("about/featchAll")
			.then()
			.finally(() => {
				this.loading = true;
			});
	},
};
</script>

<style scoped lang="scss">
.page {
	max-width: 1340px;
	margin: auto;
	margin-top: 220px;
	@media only screen and (min-width: 1400px) {
		& {
			margin-top: 255px;
		}
	}
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
	@media only screen and(max-width: 992px) {
		& {
			display: block;
		}
	}
}
.left {
	flex: 2;
	overflow: hidden;
	@media only screen and (max-width: 992px) {
		& {
			width: 100%;
		}
	}
}
</style>