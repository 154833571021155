<template>
	<router-link :to="{ name: 'product', params: { id: data.id } }" v-if="data">
		<div class="my-card">
			<div class="image">
				<img
					:key="data.primary_image"
					:alt="data.name"
					:src="data.primary_image"
				/>
			</div>
			<div class="info">
				<!-- <div class="date mt-1" v-if="!product">
					<i class="fa fa-calendar"></i>
					{{ date() }}
				</div> -->
				<p class="fw-b text-blue card-title my-1">
					{{ data.name }}
				</p>
				<p class="desription">
					{{
						data.summary.length > 70
							? data.summary.slice(0, 70) + "..."
							: data.summary
					}}
				</p>
				<p class="text-yellow fw-bold read-more">
					قراءة المزيد <i class="fa fa-angle-double-left"></i>
				</p>
			</div>
		</div>
	</router-link>
</template>

<script>
import moment from "moment";

export default {
	props: {
		data: null,
	},
	methods: {
		date() {
			return moment(this.data.updated_at).format("MMM DD YYYY");
		},
	},
};
</script>

<style scoped lang="scss">
.image {
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 30px;
	overflow: hidden;
	transition: 300ms;
	flex: flex;
}
.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 200ms;
}
.desription {
	font-size: 14px;
	line-height: 15px;
	color: black !important;
}
.card-title {
	font-size: 20px;
	line-height: 22px;
	font-family: CoconBold;
}
.my-card {
	transition: 400ms;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: space-between;
}
.my-card:hover {
	scale: 1.03;
}
.my-card:hover .image {
	box-shadow: 0 0 15px #0006;
}
.info {
	min-height: 100px;
	max-height: 100px;
}
.read-more {
	font-size: 0.8rem;
	i {
		transition: 200ms;
	}
	* {
		font-size: inherit;
	}
}
.read-more:hover i {
	scale: 1.2;
}
</style>