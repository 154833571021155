<template>
	<header class="header" ref="header" :class="{ next: !homePage }">
		<div class="go-to-top-btn" ref="goToTopBtn" @click="goToTop()">
			<img src="/Icons/Schools Tools Scrolling Up Button.png" alt="" />
		</div>
		<div class="shape">
			<div class="shape-image">
				<img src="/Shapes/Ellipse.png" alt="" class="shape-image-ellipse" />
				<router-link to="/">
					<img src="/Icons/Logo.png" alt="" class="shape-image-brand" />
				</router-link>
			</div>
		</div>
		<loader
			v-if="!loading"
			header
			class="navbar py-0"
			ref="drawer"
			style="margin-right: -10px"
		></loader>
		<div
			class="navbar py-0"
			ref="drawer"
			:class="{ active: openDrawer }"
			v-else
		>
			<ul class="list" ref="list">
				<li class="list-item d-lg-none" @click="openDrawer = !openDrawer">
					<div class="burger">
						<div class="burger-line"></div>
						<div class="burger-line"></div>
						<div class="burger-line"></div>
					</div>
				</li>
				<li class="list-item">
					<router-link to="/"> الرئيسية </router-link>
				</li>
				<li class="list-item">
					<router-link :to="{ name: 'about-us' }"> من نحن </router-link>
				</li>
				<li
					class="list-item"
					v-for="tab in tabs.filter(
						(c) => c.id != 12 && c.id != 13 && c.id != 14
					)"
					:key="tab.id"
					@click="
						subListIndex == tab.id
							? (subListIndex = null)
							: (subListIndex = tab.id)
					"
					@mouseenter="
						subListIndex == tab.id
							? (subListIndex = null)
							: (subListIndex = tab.id)
					"
					@mouseleave="subListIndex = null"
					:ref="tab.name"
				>
					{{ tab.name }}<span class="list-item-arrow"></span>
					<ul class="sub-list" :class="{ active: subListIndex === tab.id }">
						<li v-for="section in tab.sections" :key="section.id">
							<router-link
								@click="subListIndex = null"
								class="sub-list-item"
								:to="{
									name: 'all-products',
									params: { id: tab.id, routerSection: section.id },
								}"
							>
								{{ section.name }}
							</router-link>
						</li>
					</ul>
				</li>
				<li class="list-item">
					<a
						href="https://www.teacherspayteachers.com/Store/Wasael-Talimi"
						target="_blank"
						>المتجر</a
					>
				</li>
			</ul>
		</div>
	</header>
</template>

<script>
import loader from "@/components/loader.vue";
import { mapState } from "vuex";

export default {
	components: { loader },
	props: ["loading", "tabs"],
	data() {
		return {
			// loading: true,
			homePage: true,
			subListIndex: null,
			openDrawer: false,
		};
	},
	watch: {
		$route() {
			this.openDrawer = false;
			this.subListIndex = null;

			if (this.$route.name == "home") this.homePage = true;
			else this.homePage = false;
		},
	},
	methods: {
		goToTop() {
			let duration = 1000;
			if (
				window.pageYOffset >
				(document.body.clientHeight - window.innerHeight) / 2
			) {
				duration = 1000;
			}

			(function scrollToY(y, duration, element) {
				// cancel if already on target position
				if (element.scrollTop === y) return;

				const cosParameter = (element.scrollTop - y) / 2;
				let scrollCount = 0,
					oldTimestamp = null;

				function step(newTimestamp) {
					if (oldTimestamp !== null) {
						scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
						if (scrollCount >= Math.PI) return (element.scrollTop = y);
						element.scrollTop =
							cosParameter + y + cosParameter * Math.cos(scrollCount);
					}
					oldTimestamp = newTimestamp;
					window.requestAnimationFrame(step);
				}
				window.requestAnimationFrame(step);
			})(0, duration, document.scrollingElement);
		},
	},
	computed: {
		...mapState({
			sections: (state) => state.tabs.sections,
		}),
	},
	mounted() {
		window.addEventListener("scroll", () => {
			if (this.$refs["goToTopBtn"]) {
				if (document.documentElement.scrollTop > 500) {
					this.$refs["goToTopBtn"].style.visibility = "visible";
					this.$refs["goToTopBtn"].style.opacity = 1;
				} else {
					this.$refs["goToTopBtn"].style.visibility = "hidien";
					this.$refs["goToTopBtn"].style.opacity = 0;
				}
			}
			if (this.$refs["drawer"]) {
				if (document.documentElement.scrollTop > 50) {
					this.$refs["header"].style.opacity = "0.95";
					this.$refs["header"].classList.add("next");
					if (window.innerWidth > 992)
						document.querySelectorAll(".list-item,.list-item").forEach((e) => {
							e.style.padding = "15px 0 20px";
						});
				} else {
					this.$refs["header"].style.opacity = "1";
					if (this.$route.name == "home")
						this.$refs["header"].classList.remove("next");
					if (window.innerWidth > 992)
						document.querySelectorAll(".list-item,.list-item").forEach((e) => {
							e.style.padding = "30px 0 45px";
						});
				}
			}
		});
		if (this.$route.name === "home") this.homePage = true;
		else this.homePage = false;
	},
};
</script>

<style lang="scss" scoped>
.go-to-top-btn {
	display: flex;
	visibility: hidden;
	opacity: 0;
	transition: 200ms;
	justify-content: center;
	align-items: center;
	width: 70px;
	height: 70px;
	background: #eee;
	box-shadow: 1px 0 5px #0006;
	position: fixed;
	border-radius: 50%;
	bottom: 40px;
	left: 30px;
	cursor: pointer;
	img {
		height: 70%;
	}
	@media only screen and(max-width: 576px) {
		& {
			height: 60px;
			width: 60px;
		}
	}
}
.header {
	position: fixed;
	top: 0%;
	right: 0%;
	left: 0;
	display: flex;
	align-items: flex-start;
	z-index: 2;
}
.navbar {
	width: 85%;
}
.list {
	width: 100%;
	padding-left: 30px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	list-style: none;
	background-size: cover;
	&-item {
		transition: 200ms;
		> a {
			cursor: pointer;
			padding: 20px 0;
		}
		color: #fffc;
		font-family: coconBold;
		font-size: 22px;
		text-transform: capitalize;
		padding: 30px 0 45px;
		position: relative;
		cursor: pointer;
		& span {
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 2px;
			width: 10px;
			height: 10px;
			border-bottom: 2px solid white;
			border-left: 2px solid white;
			transform: rotate(-45deg);
			transition: 200ms;
		}
		&:hover span {
			transform: rotate(135deg);
		}
	}
	&-item:hover,
	&-item:hover > a {
		color: white !important;
	}
}
.sub-list {
	top: 70%;
	left: 0;
	position: absolute;
	background: #333e;
	color: #ddd;
	list-style: none;
	padding: 20px;
	border-radius: 4px;
	box-shadow: -2px 2px 10px #333;
	display: none;
	min-width: 200px;
	&-item {
		cursor: pointer;
		font-family: Cocon;
		padding: 10px;
		display: block;
	}
	&-item:hover {
		color: var(--yellow-color);
	}
}
.sub-list.active {
	display: block;
}
.shape {
	width: 57%;
	@media only screen and (max-width: 1200px) {
		width: 75%;
	}
	max-width: 650px;
	position: relative;
	transition: 300ms;
	&-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		z-index: 4;
		&-ellipse {
			width: 100%;
			object-fit: cover;
			z-index: 2;
			transition: 500ms;
			// transition-delay: 100ms;
		}
		&-brand {
			position: absolute;
			top: 20px;
			right: 50px;
			width: 30%;
			z-index: 3;
		}
	}
}
@media only screen and (min-width: 992px) {
	.next.header {
		background: var(--green-color);
		.shape {
			width: 220px;
			margin-right: -5px;
			&-image-ellipse {
				rotate: -28deg;
			}
			&-image-brand {
				width: 120px;
			}
		}
		.list {
			padding-right: 40px;
		}
	}
}
@media only screen and (max-width: 1200px) {
	.list-item {
		font-size: 20px;
		span {
			margin-right: 5px;
			width: 8px;
			height: 8px;
		}
	}
}
@media only screen and (max-width: 1100px) {
	.list-item {
		font-size: 18px;
	}
}
@media only screen and (max-width: 992px) {
	.header {
		z-index: 4;
	}
	.shape {
		width: 200px;
		&-image-brand {
			width: 120px;
			right: 20px;
		}
	}
	.navbar {
		width: 130px;
		max-width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transition: 400ms;
		> ul {
			flex-direction: column;
			padding-right: 20px;
			align-items: flex-start;
		}
		z-index: 5;
		border-bottom-right-radius: 30px;
	}
	.next .navbar {
		background: var(--green-color);
	}
	.loader {
		border-bottom-right-radius: 30px;
	}
	.navbar.active {
		background: var(--green-color);
		width: 300px;
		box-shadow: 1px 1px 7px #333;

		.list .list-item {
			padding: 20px 0 !important;
			max-height: 500px;
			overflow: visible;
		}
		.list .list-item:last-child {
			padding-bottom: 40px;
		}
		.list .list-item:first-child {
			margin-left: 10px;
			padding: 30px 0;
		}
	}
	.list-item {
		padding: 0 !important;
		max-height: 0;
		transition: padding 400ms, max-height 100ms;
		overflow: hidden;
		font-size: 20px;
	}
	.list-item:first-child {
		padding: 30px 0 !important;
		margin-left: 20px;
		max-height: unset;
		align-self: flex-end;
		transition: 300ms;
	}
	.sub-list {
		position: relative;
		background: transparent;
		box-shadow: none;
		padding: 0 20px;
	}
	.burger {
		position: relative;
		width: 30px;
		height: 30px;
		&-line {
			background: white;
			width: 100%;
			height: 5px;
			margin-bottom: 5px;
			border-radius: 10px;
			transition: 500ms;
		}
		.navbar.active &-line:first-child {
			transform: rotate(45deg) translate(5px, 5px);
		}
		.navbar.active &-line:last-child {
			transform: rotate(-45deg) translate(9px, -9px);
		}
		.navbar.active &-line:nth-child(2) {
			opacity: 0;
		}
	}
}
</style>