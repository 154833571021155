import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        video: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/albums')
                .then((response) => {
                    commit(types.VIDEO, response.data);
                    return response.data
                })
        }
    },

    mutations: {
        [types.VIDEO](state, video) {
            state.video = video
        }
    }
}