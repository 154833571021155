import Home from '../views/Home/Home.vue'
import Article from '../views/Article/Article.vue'
import AllArticles from '../views/Article/AllArticles.vue'
import Product from '../views/Product/Product.vue'
import AllProducts from '../views/Product/AllProducts.vue'
import AboutUs from "../views/AboutUs/AboutUs.vue"
import Search from '../views/Product/Search.vue'

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/article',
        name: 'article',
        component: Article
    },
    {
        path: '/product/:id',
        name: 'product',
        component: Product
    },
    {
        path: '/all-products/:id/:routerSection?',
        name: 'all-products',
        component: AllProducts,
        props: true
    },
    {
        path: '/news/:id',
        name: 'news',
        component: Article
    },
    {
        path: '/all-news',
        name: 'all-news',
        component: AllArticles
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {

        path: '/search/:id',
        name: 'search',
        component: Search

    }
]