<template>
	<div class="right pe-lg-4 me-lg-4 ps-0 ms-0">
		<section class="mb-5" ref="section1" id="section1">
			<div class="right-top d-lg-block d-md-flex d-sm-block">
				<div>
					<div
						class="text-uppercase text-green fs-5"
						style="margin-bottom: -20px"
					>
						مرحبا
					</div>
					<p class="schools-tools-logo">
						وسائل تعليمي
						<img src="/Icons/tree.png" alt="" />
					</p>
					<div class="paragraph" ref="paragraph">
						<div
							ref="innerParagraph"
							v-html="aboutParagraph"
							style="text-align: justify"
						></div>
						<router-link class="paragraph-overflow" :to="{ name: 'about-us' }"
							>اقرأ المزيد...</router-link
						>
					</div>
				</div>
				<div
					class="
						d-lg-block d-md-flex d-sm-block
						ps-lg-0 ps-md-2
						right-top-find-us
					"
					style="align-items: center"
				>
					<div>
						<p class="my-3 text-green fe-5">تجدوني على</p>
						<div class="teachers-pay-teachers">
							<a
								href="https://www.teacherspayteachers.com/Store/Wasael-Talimi"
								target="_blank"
							>
								<img src="/Icons/teachers pay teachers.png" alt="" />
							</a>
						</div>
					</div>
					<div class="search d-md-block d-none">
						<p class="text-yellow mb-3 fe-5">تبحث عن شيء؟</p>
						<form class="right-top-form" @submit.prevent="submit">
							<input
								type="text"
								class="right-top-form-input"
								placeholder="بحث"
								v-model="search"
							/>
							<button class="right-top-form-btn">
								<i class="fa fa-search"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
		<div class="right-bottom">
			<section ref="section2" class="mb-5">
				<p class="title text-uppercase mb-3">منتجات مميزة</p>
				<div class="right-bottom-featured">
					<loader
						spiner
						:width="'100px'"
						style="display: flex; justify-content: center"
						v-if="!loading"
					></loader>
					<product-card
						v-else
						:img="true"
						:imageHeight="'250px'"
						v-for="product in products.filter((c) => c.is_featured).slice(0, 3)"
						:key="product.id"
						:data="product"
					></product-card>
				</div>
			</section>
			<p class="title text-uppercase mb-3">المزيد من المقالات</p>
			<div class="right-bottom-more" ref="section3">
				<article-card
					:imageHeight="'150px'"
					:horizontal="true"
					v-for="article in news.filter((c) => c.is_featured).slice(0, 3)"
					:key="article.id"
					:data="article"
				></article-card>
			</div>
		</div>
	</div>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import ArticleCard from "./ArticleCard.vue";
import { mapState } from "vuex";
import Loader from "./loader.vue";

export default {
	components: { ProductCard, Loader, ArticleCard },
	data() {
		return {
			search: null,
			loading: false,
			height: 0,
		};
	},
	computed: {
		aboutParagraph() {
			return this.about.find((e) => e.Key === "about")?.Value;
		},
		...mapState({
			products: (state) => state.products.products,
			news: (state) => state.news.news,
			about: (state) => state.about.about,
		}),
	},
	methods: {
		featchProducts() {
			this.$store.dispatch("products/featchAll").then((data) => {
				this.loading = true;
			});
		},
		featchNews() {
			this.$store.dispatch("news/featchAll").then((data) => {});
		},
		submit() {
			this.$router.push({ name: "search", params: { id: this.search } });
		},
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.$emit("height", [this.$refs["section1"]?.clientHeight]);
		});
		this.featchNews();
		this.$store.dispatch("about/featchAll").then(() => {
			// this.$emit("height", [this.$refs["section1"]?.clientHeight]);
			if (
				this.$refs.paragraph.clientHeight <
				this.$refs.innerParagraph.clientHeight
			) {
				this.$refs.paragraph.classList.add("overflow");
			}
		});
		if (this.products.length === 0) this.featchProducts();
		else this.loading = true;
	},
};
</script>

<style lang='scss' scoped>
.right {
	width: 33%;
	min-width: 370px;
	border-right: 1px solid grey;
	@media only screen and(max-width:992px) {
		& {
			width: 100%;
			border: none;
			min-width: unset;
		}
	}
	&-top {
		&-form {
			position: relative;
			height: 50px;
			border: 1px solid grey;
			border-radius: 30px;
			&-input {
				border-radius: 30px;
				min-width: 0;
				height: 100%;
				outline: none;
				padding: 10px 20px;
				padding-left: 0;
				color: grey;
				border: none;
			}
			&-btn {
				position: absolute;
				left: -1px;
				top: -1px;
				bottom: -1px;
				min-width: 50px;
				border: none;
				background: var(--yellow-color);
				color: white;
				font-size: 24px;
				border-radius: 30px;
			}
		}
		@media only screen and(max-width:992px) {
			&-find-us {
				flex-direction: column;
			}
		}
	}
	&-bottom {
		&-featured {
			width: 100%;
			padding-left: 100px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			cursor: pointer;
			align-items: center;
			justify-content: space-between;
			@media (max-width: 992px) {
				flex-direction: row;
				padding: 0;
			}
			> a {
				width: 18vw;
				height: calc(18vw + 100px);
				margin-bottom: 33px;
			}
			@media (min-width: 1500px) {
				> a {
					max-width: 270px;
					max-height: 370px;
				}
			}
			@media (max-width: 992px) {
				> a {
					width: 30vw;
					height: calc(30vw + 100px);
				}
			}
			@media (max-width: 768px) {
				> a {
					width: 44vw;
					height: calc(44vw + 100px);
				}
				> a:last-child {
					margin: auto;
				}
			}
			@media (max-width: 578px) {
				justify-content: center;
				> a {
					width: 85vw;
					height: calc(85vw + 100px);
				}
			}
		}
		&-more {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			max-width: 450px;
			margin: auto;
			> a {
				margin-bottom: 10px;
			}
			margin-bottom: 30px;
		}
	}
}
.teachers-pay-teachers {
	width: 80%;
	max-width: 400px;
	margin-bottom: 20px;
	@media only screen and(max-width:576px) {
		& {
			width: 70%;
			max-width: 340px;
		}
	}
	img {
		width: 100%;
	}
}
.schools-tools-logo {
	font-family: coconBold;
	font-size: 3vw;
	text-transform: capitalize;
	width: max-content;
	color: var(--pink-color);
	@media only screen and(max-width:992px) {
		& {
			font-size: 6vw;
		}
	}
	@media only screen and(max-width:576px) {
		& {
			font-size: 30px;
		}
	}
	img {
		height: 8vw;
		padding-bottom: 10px;
		@media only screen and(max-width:992px) {
			& {
				height: 12vw;
			}
		}
		@media only screen and (max-width: 576px) {
			& {
				height: 70px;
			}
		}
	}
}
.title {
	font-size: 1.4rem;
	font-family: coconBold;
}

.paragraph-overflow {
	display: none;
}
.paragraph.overflow {
	position: relative;
	.paragraph-overflow {
		display: block;
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		background: linear-gradient(to bottom, transparent, white 30%);
		padding-top: 10px;
		color: var(--red-color);
		font-size: 0.7rem;
	}
}
</style>
<style>
#section1 {
	min-height: 760px;
	max-height: 860px;
	height: 56vw;
}
.paragraph {
	height: 310px;
	overflow: hidden;
}
@media (max-width: 992px) {
	#section1 {
		height: auto;
		min-height: unset;
		max-height: unset;
	}
	.paragraph {
		height: auto;
	}
}
.paragraph * {
	font-size: 1rem;
}
.paragraph p {
	margin-bottom: 0.5rem;
}
</style>