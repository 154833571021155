import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        tabs: [],
        sections: [],
        numOfSections: 0,
        loadAllSections: false
    },

    actions: {
        featchTabsAndSections({ commit, dispatch, state }) {
            dispatch('featchAll').then(() => {
                state.tabs.forEach(e => {
                    dispatch("featchSections", { id: e.id })
                        .then(() => {
                        })
                })
            })
        },
        featchAll({ commit, dispatch, state }) {
            return axios.get('/tabs')
                .then((response) => {
                    commit(types.TABS, response.data);
                    return response.data
                })

        },
        featchById({ commit }, { id }) {
            return axios.get('/tabs/' + id)
                .then((response) => {
                    return response.data
                })
        },
        featchSections({ commit, state }, { id }) {
            axios.get('/tabs/' + id)
                .then(response => {
                    const element = {}
                    element.id = response.data.id
                    element.section = response.data.sections
                    state.sections.push(element)

                    state.numOfSections++
                    if (state.tabs.length === state.numOfSections) {
                        state.loadAllSections = true
                    }
                })
        }
    },

    mutations: {
        [types.TABS](state, tabs) {
            state.tabs = tabs
        }
    }
}