<template>
	<div class="page">
		<loader
			spiner
			:width="'100px'"
			style="margin: 100px auto; display: flex; justify-content: center"
			class="left"
			v-if="!loading"
		></loader>
		<div class="left" v-else>
			<breadcrumbs class="mt-3 mb-5 breadcrumbs" :links="BreadcrumbsItems()">
			</breadcrumbs>
			<div class="date">
				<i class="fa fa-calendar"></i>
				{{ date() }}
			</div>
			<div
				class="main-title fs-1 mb-2"
				style="font-family: CoconBold; line-height: 60px"
			>
				{{ product.name }}
			</div>
			<div class="left-buttons mb-3">
				<a :href="product.url" target="_blank">
					<div
						class="left-buttons-button"
						style="background: var(--green-color)"
						v-if="product.url"
					>
						المتجر
					</div>
				</a>
				<a :href="product.files[0]">
					<div
						class="left-buttons-button"
						style="background: var(--pink-color)"
						v-if="product.files.length"
					>
						تحميل
					</div>
				</a>
			</div>
			<div class="left-image">
				<div
					:style="'background-image: url(' + product.primary_image + ')'"
				></div>
			</div>

			<div v-html="product.description" class="description"></div>
			<p class="title mt-5 mb-3">منتجات ذات صلة</p>
			<div class="related-products" v-if="loading">
				<product-card
					:imageHeight="200"
					:product="true"
					v-for="relatedProduct in products
						.filter(
							(c) => c.id !== product.id && filterBySections(c.sections_ids)
						)
						.slice(0, 3)"
					:key="relatedProduct.id"
					:data="relatedProduct"
				></product-card>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import ProductCard from "../../components/ProductCard.vue";
import RightSide from "../../components/RightSide.vue";
import { mapState } from "vuex";
import Loader from "@/components/loader.vue";
import moment from "moment";

export default {
	components: {
		Breadcrumbs,
		ProductCard,
		RightSide,
		Loader,
	},
	data() {
		return {
			product: {},
			loading: false,
			prevSection: null,
			prevTab: null,
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vw) => {
			if (from.name == "all-products") {
				vw.prevTab = from.params.id;
				if (from.params.routerSection)
					vw.prevSection = from.params.routerSection;
			}
		});
	},
	watch: {
		"$route.params"() {
			this.featchProduct();
		},
	},
	methods: {
		BreadcrumbsItems() {
			var arr = [{ name: "الرئيسية", path: "/" }];
			var x;
			if (this.prevTab) {
				x = this.tabs.find((e) => e.id == this.prevTab);
				arr.push({
					name: x.name,
					path: { name: "المنتجات", params: { id: this.prevTab } },
				});
			} else {
				x = this.tabs.find((e) => e.id == this.product.tabs_ids[0]);
				arr.push({
					name: x.name,
					path: { name: "المنتجات", params: { id: x.id } },
				});
			}
			if (this.prevSection) {
				x = this.sections.find((e) => e.id == this.prevSection);
				arr.push({
					name: x.name,
					path: {
						name: "المنتحات",
						params: { id: this.prevTab, routerSection: this.prevSection },
					},
				});
			}
			arr.push({
				name: this.product.name,
				path: "",
				active: true,
			});

			return arr;
		},
		featchProduct() {
			this.loading = false;
			this.$store
				.dispatch("products/featchById", { id: this.$route.params.id })
				.then((data) => {
					this.product = data[0];
					this.featchSections();
					console.log(this.product.files[0]);
				});
		},
		featchSections() {
			this.$store.dispatch("sections/featchAll").finally(() => {
				this.BreadcrumbsItems();
				this.loading = true;
			});
		},
		filterBySections(sectionsIds) {
			var found = false;
			this.product["sections_ids"]?.forEach((thisSection) => {
				sectionsIds.forEach((section) => {
					if (thisSection === section) {
						found = true;
						return;
					}
				});
			});
			return found;
		},
		date() {
			return moment(this.product.updated_at).format("MMM DD YYYY");
		},
	},
	computed: {
		...mapState({
			products: (state) => state.products.products,
			tabs: (state) => state.tabs.tabs,
			sections: (state) => state.sections.sections,
		}),
	},
	mounted() {
		this.featchProduct();
	},
	metaInfo() {
		return {
			title: this.product.name,
			titleTemplate: "%s | وسائل تعليمي",
		};
	},
};
</script>

<style lang='scss' scoped>
.breadcrumbs {
	margin-right: 300px;
	@media only screen and (max-width: 992px) {
		margin-right: 0;
	}
}
.page {
	position: relative;
	max-width: 1450px;
	margin: auto;
	margin-top: 130px;
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
	@media only screen and(max-width: 992px) {
		& {
			display: block;
			margin-top: 190px;
		}
	}
}
.left {
	width: 66%;
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
	&-cata {
		display: flex;
		align-items: center;
		&-item {
			background: #d9d9d9;
			border-radius: 20px;
			margin-right: 10px;
			padding: 5px 20px;
			font-size: 15px;
			text-transform: capitalize;
			color: #444;
		}
	}
	&-share {
		display: flex;
		align-items: center;
		font-size: 14px;
		&-item {
			margin-left: 5px;
			border: 1px solid black;
			border-radius: 50%;
		}
	}
	&-image {
		height: 56vw;
		width: 56vw;
		max-width: 810px;
		max-height: 810px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		border-radius: 30px;
		margin: auto;
		margin-bottom: 1rem;
		@media only screen and(max-width: 992px) {
			max-width: unset;
			max-height: unset;
			width: 90vw;
			height: 60vw;
		}
		@media (max-width: 576px) {
			height: 90vw;
		}
		div {
			width: 100%;
			height: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
	&-video {
		height: 500px;
		@media only screen and(max-width: 768px) {
			& {
				height: 450px;
			}
		}
		@media only screen and(max-width: 576px) {
			& {
				height: 350px;
			}
		}
	}
	&-buttons {
		display: flex;
		justify-content: flex-start;
		&-button {
			padding: 10px 30px;
			margin-left: 10px;
			border-radius: 30px;
			font-size: 20px;
			color: white;
			font-family: coconBold;
			text-transform: uppercase;
			> a {
				width: 100%;
				height: 100%;
				color: white;
			}
		}
	}
}
.related-products {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	> a {
		width: 18vw;
		height: calc(18vw + 100px);
		margin-bottom: 20px;
	}
	@media (min-width: 1500px) {
		> a {
			max-width: 270px;
			max-height: 370px;
		}
	}
	@media (max-width: 992px) {
		> a {
			width: 30vw;
			height: calc(30vw + 100px);
		}
	}
	@media (max-width: 768px) {
		> a:last-child {
			display: none;
		}
	}
	@media (max-width: 768px) {
		> a {
			width: 44vw;
			height: calc(44vw + 100px);
		}
	}
	@media (max-width: 578px) {
		justify-content: center;
		> a {
			width: 85vw;
			height: calc(85vw + 100px);
		}
	}
}
.title {
	font-size: 1.2rem;
	font-family: CoconBold;
}
.paragraph {
	margin-bottom: 1rem;
	span {
		font-size: inherit;
		font-weight: bolder;
	}
}
</style>
<style>
.description * {
	max-width: 100% !important;
}
</style>