import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        about: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/about-us')
                .then((response) => {
                    commit(types.ABOUT, response.data);
                    return response.data
                })
        },
    },

    mutations: {
        [types.ABOUT](state, about) {
            state.about = about
        }
    }
}