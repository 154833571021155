var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"header",staticClass:"header",class:{ next: !_vm.homePage }},[_c('div',{ref:"goToTopBtn",staticClass:"go-to-top-btn",on:{"click":function($event){return _vm.goToTop()}}},[_c('img',{attrs:{"src":"/Icons/Schools Tools Scrolling Up Button.png","alt":""}})]),_c('div',{staticClass:"shape"},[_c('div',{staticClass:"shape-image"},[_c('img',{staticClass:"shape-image-ellipse",attrs:{"src":"/Shapes/Ellipse.png","alt":""}}),_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"shape-image-brand",attrs:{"src":"/Icons/Logo.png","alt":""}})])],1)]),(!_vm.loading)?_c('loader',{ref:"drawer",staticClass:"navbar py-0",staticStyle:{"margin-right":"-10px"},attrs:{"header":""}}):_c('div',{ref:"drawer",staticClass:"navbar py-0",class:{ active: _vm.openDrawer }},[_c('ul',{ref:"list",staticClass:"list"},[_c('li',{staticClass:"list-item d-lg-none",on:{"click":function($event){_vm.openDrawer = !_vm.openDrawer}}},[_c('div',{staticClass:"burger"},[_c('div',{staticClass:"burger-line"}),_c('div',{staticClass:"burger-line"}),_c('div',{staticClass:"burger-line"})])]),_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" الرئيسية ")])],1),_c('li',{staticClass:"list-item"},[_c('router-link',{attrs:{"to":{ name: 'about-us' }}},[_vm._v(" من نحن ")])],1),_vm._l((_vm.tabs.filter(
					function (c) { return c.id != 12 && c.id != 13 && c.id != 14; }
				)),function(tab){return _c('li',{key:tab.id,ref:tab.name,refInFor:true,staticClass:"list-item",on:{"click":function($event){_vm.subListIndex == tab.id
						? (_vm.subListIndex = null)
						: (_vm.subListIndex = tab.id)},"mouseenter":function($event){_vm.subListIndex == tab.id
						? (_vm.subListIndex = null)
						: (_vm.subListIndex = tab.id)},"mouseleave":function($event){_vm.subListIndex = null}}},[_vm._v(" "+_vm._s(tab.name)),_c('span',{staticClass:"list-item-arrow"}),_c('ul',{staticClass:"sub-list",class:{ active: _vm.subListIndex === tab.id }},_vm._l((tab.sections),function(section){return _c('li',{key:section.id},[_c('router-link',{staticClass:"sub-list-item",attrs:{"to":{
								name: 'all-products',
								params: { id: tab.id, routerSection: section.id },
							}},on:{"click":function($event){_vm.subListIndex = null}}},[_vm._v(" "+_vm._s(section.name)+" ")])],1)}),0)])}),_c('li',{staticClass:"list-item"},[_c('a',{attrs:{"href":"https://www.teacherspayteachers.com/Store/Wasael-Talimi","target":"_blank"}},[_vm._v("المتجر")])])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }