var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(!_vm.loading)?_c('loader',{staticClass:"left",staticStyle:{"margin":"100px auto","display":"flex","justify-content":"center"},attrs:{"spiner":"","width":'100px'}}):_c('div',{staticClass:"left"},[_c('breadcrumbs',{staticClass:"mb-5 mt-3 breadcrumbs",attrs:{"links":[
				{ name: 'الرئيسية', path: '/' },
				{ name: 'مدوّنة', path: '/all-news' },
				{
					name: _vm.article.title,
					path: '/',
					active: true,
				} ]}}),_c('div',{staticClass:"date mt-lg-5 mt-0"},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" "+_vm._s(_vm.date())+" ")]),_c('div',{staticClass:"main-title fs-1 mb-2",staticStyle:{"font-family":"CoconBold"}},[_vm._v(" "+_vm._s(_vm.article.title)+" ")]),_c('div',{staticClass:"left-share mb-3"},[_vm._v(" مشاركة على "),_c('div',{staticClass:"left-share-item"},[_c('share-network',{attrs:{"network":"facebook","url":_vm.url,"title":_vm.article.title}},[_c('i',{staticClass:"fa-brands fa-facebook-f",staticStyle:{"color":"#3a5797"}})])],1),_c('div',{staticClass:"left-share-item"},[_c('share-network',{attrs:{"network":"pinterest","url":_vm.url,"title":_vm.article.title}},[_c('i',{staticClass:"fa-brands fa-pinterest-p",staticStyle:{"color":"#c41620"}})])],1),_c('div',{staticClass:"left-share-item"},[_c('share-network',{attrs:{"network":"twitter","url":_vm.url,"title":_vm.article.title}},[_c('i',{staticClass:"fa-brands fa-twitter",staticStyle:{"color":"#0098fd"}})])],1)]),_c('div',{staticClass:"article-content",domProps:{"innerHTML":_vm._s(_vm.article.content)}}),(_vm.news.filter(function (c) { return c.id != _vm.article.id; }).length > 0)?_c('p',{staticClass:"title mt-5 mb-3"},[_vm._v(" مقالات ذات صلة ")]):_vm._e(),_c('div',{staticClass:"related-articles"},_vm._l((_vm.news.filter(function (c) { return c.id != _vm.article.id; })),function(relatedArticle){return _c('article-card',{key:relatedArticle.id,attrs:{"imageHeight":200,"product":true,"data":relatedArticle}})}),1)],1),_c('right-side')],1)}
var staticRenderFns = []

export { render, staticRenderFns }