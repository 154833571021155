import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products'
import tabs from './modules/tabs'
import filters from './modules/filters'
import sections from './modules/sections'
import news from './modules/news'
import subscription from './modules/subscription'
import slider from './modules/slider'
import about from './modules/about'
import video from './modules/video'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    products,
    tabs,
    filters,
    sections,
    news,
    subscription,
    slider,
    about,
    video
  }
})
