<template>
	<div class="page">
		<loader
			spiner
			:width="'100px'"
			v-if="!loading"
			style="margin: 100px auto; display: flex; justify-content: center"
			class="left"
		></loader>
		<div class="left" v-else>
			<breadcrumbs
				class="mb-5 mt-3 breadcrumbs"
				:links="[
					{ name: 'الرئيسية', path: '/' },
					{ name: 'مدوّنة', path: '/all-news' },
					{
						name: article.title,
						path: '/',
						active: true,
					},
				]"
			>
			</breadcrumbs>
			<div class="date mt-lg-5 mt-0">
				<i class="fa fa-calendar"></i>
				{{ date() }}
			</div>
			<div class="main-title fs-1 mb-2" style="font-family: CoconBold">
				{{ article.title }}
			</div>
			<div class="left-share mb-3">
				مشاركة على
				<div class="left-share-item">
					<share-network network="facebook" :url="url" :title="article.title">
						<i class="fa-brands fa-facebook-f" style="color: #3a5797"></i>
					</share-network>
				</div>
				<div class="left-share-item">
					<share-network network="pinterest" :url="url" :title="article.title">
						<i class="fa-brands fa-pinterest-p" style="color: #c41620"></i>
					</share-network>
				</div>
				<div class="left-share-item">
					<share-network network="twitter" :url="url" :title="article.title">
						<i class="fa-brands fa-twitter" style="color: #0098fd"></i>
					</share-network>
				</div>
			</div>
			<div v-html="article.content" class="article-content"></div>

			<p
				class="title mt-5 mb-3"
				v-if="news.filter((c) => c.id != article.id).length > 0"
			>
				مقالات ذات صلة
			</p>
			<div class="related-articles">
				<article-card
					:imageHeight="200"
					:product="true"
					v-for="relatedArticle in news.filter((c) => c.id != article.id)"
					:key="relatedArticle.id"
					:data="relatedArticle"
				></article-card>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import Loader from "@/components/loader.vue";
import Breadcrumbs from "../../components/Breadcrumbs.vue";
import ArticleCard from "../../components/ArticleCard.vue";
import RightSide from "../../components/RightSide.vue";
import moment from "moment";

import { mapState } from "vuex";

export default {
	components: {
		Breadcrumbs,
		ArticleCard,
		RightSide,
		Loader,
	},
	data() {
		return {
			article: {},
			loading: false,
		};
	},
	computed: {
		url() {
			return window.location.href;
		},
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	watch: {
		"$route.params"() {
			this.loading = false;
			this.featchArticle();
		},
	},
	methods: {
		featchArticle() {
			this.$store
				.dispatch("news/featchById", { id: this.$route.params.id })
				.then((data) => {
					this.article = data;
					this.loading = true;
				});
		},
		date() {
			return moment(this.article.updated_at).format("MMM DD YYYY");
		},
	},
	mounted() {
		this.featchArticle();
	},
};
</script>
<style >
.article-content * {
	font-family: Cocon !important;
	font-size: 16px !important;
}
</style>

<style lang='scss' scoped>
.breadcrumbs {
	margin-right: 300px;
	@media only screen and (max-width: 992px) {
		margin-right: 0;
	}
}
.page {
	max-width: 1450px;
	margin: auto;
	margin-top: 130px;
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
	@media only screen and(max-width: 992px) {
		& {
			display: block;
			margin-top: 190px;
		}
	}
}
.left {
	width: 66%;
	@media only screen and (max-width: 992px) {
		width: 100%;
	}
	&-share {
		display: flex;
		align-items: center;
		font-size: 14px;
		width: 66%;
		&-item {
			margin-left: 5px;
			border: 1px solid #222;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			* {
				font-size: 15px;
			}
		}
	}
	&-image {
		height: 500px;
		@media only screen and(max-width: 768px) {
			& {
				height: 450px;
			}
		}
		@media only screen and(max-width: 576px) {
			& {
				height: 350px;
			}
		}
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		border-radius: 30px;
		margin-bottom: 1rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.related-articles {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	@media only screen and(max-width:768px) {
		& {
			grid-template-columns: 1fr 1fr;
		}
	}
	@media only screen and(max-width:576px) {
		& {
			grid-template-columns: 1fr;
		}
	}
	@media only screen and(max-width:1200px) and (min-width: 992px) {
		& {
			grid-template-columns: 1fr 1fr;
		}
	}
	row-gap: 10px;
	column-gap: 20px;
}
.title {
	font-size: 1.2rem;
	font-family: CoconBold;
}
.paragraph {
	margin-bottom: 1rem;
	span {
		font-size: inherit;
		font-weight: bolder;
	}
}
</style>