<template>
	<div class="loader">
		<div
			v-if="isValid('spiner')"
			class="loader-spiner"
			:style="{
				'min-width': width,
				width: width,
				'min-height': width,
				height: width,
			}"
		>
			<img src="/Icons/book.png" alt="" />
		</div>
		<div class="loader-header" v-if="isValid('header')"></div>
		<div class="loader-card" v-if="isValid('card')"></div>
	</div>
</template>

<script>
export default {
	props: ["line", "spiner", "header", "width", "card"],

	methods: {
		isValid(prop) {
			return this[prop] || typeof this[prop] === "string" ? true : false;
		},
	},
};
</script>

<style lang="scss">
.loader {
	&-spiner {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 10px solid transparent;
		position: relative;
		animation: book 1.5s linear 0s infinite alternate forwards;
		background: transparent;
		img {
			height: 100%;
			width: 100%;
		}
	}
	&-header {
		background: transparent;
		width: 100%;
		height: 110px;
		position: relative;
		overflow: hidden;
		&::after {
			animation: headerMove 1s ease-in 0s infinite normal forwards;
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 2;
			background: linear-gradient(
				110deg,
				transparent 20%,
				#8ea004 30% 40%,
				transparent 70%
			);
			top: 0;
			bottom: 0;
			@media only screen and(max-width:992px) {
				animation: headerMove 0.7s ease-in 0s infinite normal forwards;
			}
		}
	}
	&-card {
		width: 100%;
		position: relative;
		border-radius: 20px;
		box-shadow: 5px 5px 10px 3px #0003;
		height: 20vw;
		background: linear-gradient(
			90deg,
			#ececec 8%,
			var(--green-color) 18%,
			#ececec 27%
		);
		background-size: 200% 100%;
		animation: cardMove 1s linear 0s infinite normal forwards;
		&::after {
			content: "";
		}
	}
}
@keyframes cardMove {
	0% {
		background-position-x: 0%;
	}
	100% {
		background-position-x: 200%;
	}
}
@keyframes headerMove {
	0% {
		left: -100%;
	}

	100% {
		left: 100%;
	}
}
@keyframes book {
	0% {
		transform: translateY(15px) rotateY(0);
	}
	50% {
		transform: translateY(0) rotateY(180deg);
	}
	100% {
		transform: translateY(15px) rotateY(0);
	}
}
@keyframes color {
	0% {
		border-color: var(--red-color) transparent;
	}
	20% {
		border-color: var(--red-color) transparent;
	}
	25% {
		border-color: transparent var(--blue-color);
	}
	45% {
		border-color: transparent var(--blue-color);
	}
	50% {
		border-color: var(--green-color) transparent;
	}
	70% {
		border-color: var(--green-color) transparent;
	}
	75% {
		border-color: transparent var(--yellow-color);
	}
	95% {
		border-color: transparent var(--yellow-color);
	}
	100% {
		border-color: var(--red-color) transparent;
	}
}
</style>