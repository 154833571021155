import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        news: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/news')
                .then((response) => {
                    commit(types.NEWS, response.data);
                    return response.data
                })
        },
        featchById({ commit }, { id }) {
            return axios.get('/news/' + id)
                .then((response) => {
                    return response.data
                })
        },
    },

    mutations: {
        [types.NEWS](state, news) {
            state.news = news
        }
    }
}