import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        products: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/items')
                .then((response) => {
                    commit(types.PRODUCTS, response.data);
                    return response.data
                })
        },
        featchById({ commit }, { id }) {
            return axios.get('/items/' + id)
                .then((response) => {
                    return response.data
                })
        },
        featchBySearch({ commit }, { search }) {
            return axios.get('/items/search', { params: { text: search } })
                .then((response) => {
                    return response.data
                })
        }
    },

    mutations: {
        [types.PRODUCTS](state, products) {
            state.products = products
        }
    }
}