<template>
	<div style="background: #212121">
		<div class="social">
			<div class="social-item">
				<div class="social-item-top" style="background: #3b5999">
					<div class="social-item-top-text">Facebook</div>
					<i class="fa-brands fa-facebook-f"></i>
				</div>
				<div class="social-item-image">
					<div
						class="fb-page"
						data-href="https://www.facebook.com/%D9%88%D8%B3%D8%A7%D8%A6%D9%84-%D8%AA%D8%B9%D9%84%D9%8A%D9%85%D9%8A-105351017996883/"
						data-tabs="timeline"
						data-width="300"
						data-height="400"
						data-small-header="false"
						data-adapt-container-width="true"
						data-hide-cover="false"
						data-show-facepile="false"
						data-lazy="true"
					>
						<blockquote
							cite="https://www.facebook.com/%D9%88%D8%B3%D8%A7%D8%A6%D9%84-%D8%AA%D8%B9%D9%84%D9%8A%D9%85%D9%8A-105351017996883/"
							class="fb-xfbml-parse-ignore"
						>
							<a
								href="https://www.facebook.com/%D9%88%D8%B3%D8%A7%D8%A6%D9%84-%D8%AA%D8%B9%D9%84%D9%8A%D9%85%D9%8A-105351017996883/"
								target="_blank"
								>‏‎وسائل تعليمي‎‏</a
							>
						</blockquote>
					</div>
				</div>
			</div>
			<div class="social-item">
				<div class="social-item-top" style="background: #bc2a8d">
					<div class="social-item-top-text">Instagram</div>
					<i class="fa-brands fa-instagram"></i>
				</div>
				<div class="social-item-image">
					<div
						loading="lazy"
						data-mc-src="63901b82-fe8f-4764-a3e1-7cf29eb5e3c3#instagram"
					></div>
				</div>
			</div>
			<div class="social-item">
				<div class="social-item-top" style="background: #c8222c">
					<div class="social-item-top-text">Pinterest</div>
					<i class="fa-brands fa-pinterest-p"></i>
				</div>
				<div class="social-item-image">
					<a
						data-pin-do="embedUser"
						data-pin-board-width="300"
						data-pin-scale-height="285"
						data-pin-scale-width="20"
						href="https://www.pinterest.com/wasaeltalimi/_created/"
					></a>
				</div>
			</div>
		</div>
		<footer class="footer">
			<div class="footer-top">
				<div class="footer-top-text">
					<div
						class="footer-top-text-title text-uppercase text-yellow fs-5 py-1"
					>
						اشترك بالقائمة البريدية
					</div>
					<div class="footer-top-text-description">
						قم بالتسجيل لتلقي التحديثات مباشرة إلى الصندوق الوارد الخاص بك
					</div>
				</div>
				<div class="footer-top-form">
					<form @submit.prevent="submit" novalidate ref="form" v-if="!isSent">
						<input
							type="text"
							placeholder="الاسم الكامل"
							class="footer-top-form-input footer-top-form-item"
							:class="{ 'footer-top-form-input-error': notValid }"
							v-model="name"
							required
						/>
						<input
							type="email"
							placeholder="البريد الالكتروني"
							class="footer-top-form-input footer-top-form-item"
							:class="{ 'footer-top-form-input-error': notValid }"
							v-model="email"
							required
						/>
						<button
							class="footer-top-form-button footer-top-form-item"
							v-if="!submitLoading"
						>
							اشتراك
						</button>
						<loader v-else class="px-4" spiner width="40px"></loader>
					</form>
					<div class="fs-4 text-yellow ms-3" v-else>شكرا لاشتراكك</div>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="footer-bottom-image">
					<router-link to="/">
						<img src="/Icons/Logo.png" alt="" />
					</router-link>
				</div>
				<div class="footer-bottom-list" v-if="loading">
					<ul>
						<li class="footer-bottom-list-item">
							<router-link to="/"> الرئيسية </router-link>
						</li>
						<li class="footer-bottom-list-item">
							<router-link :to="{ name: 'about-us' }"> من نحن </router-link>
						</li>
						<li
							class="footer-bottom-list-item"
							v-for="tab in tabs"
							:key="tab.id"
						>
							<router-link
								:to="{ name: 'all-products', params: { id: tab.id } }"
							>
								{{ tab.name }}
							</router-link>
						</li>
						<li class="footer-bottom-list-item">
							<router-link :to="{ name: 'all-news' }"> مدوّنة </router-link>
						</li>
						<li class="footer-bottom-list-item">
							<a
								href="https://www.teacherspayteachers.com/Store/Wasael-Talimi"
								target="_blank"
								>المتجر</a
							>
						</li>
					</ul>
				</div>
				<div class="footer-bottom-social">
					<div class="footer-bottom-social-item">
						<a
							href="https://www.facebook.com/%D9%88%D8%B3%D8%A7%D8%A6%D9%84-%D8%AA%D8%B9%D9%84%D9%8A%D9%85%D9%8A-105351017996883/"
							target="_blank"
						>
							<i class="fa-brands fa-facebook-f"></i>
						</a>
					</div>
					<div class="footer-bottom-social-item">
						<a href="https://www.instagram.com/wasael_talimi/" target="_blank">
							<i class="fa-brands fa-instagram"></i>
						</a>
					</div>
					<div class="footer-bottom-social-item">
						<a href="https://www.pinterest.com/wasaeltalimi/" target="_blank">
							<i class="fa-brands fa-pinterest-p"></i>
						</a>
					</div>
					<div class="footer-bottom-social-item">
						<a href="https://twitter.com/Wasael_Talimi" target="_blank">
							<i class="fa-brands fa-twitter"></i>
						</a>
					</div>
					<div class="footer-bottom-social-item">
						<a
							href="https://www.youtube.com/channel/UCMhG2zxGYFePN7wJBQTYb3Q"
							target="_blank"
						>
							<i class="fa-brands fa-youtube"></i>
						</a>
					</div>
				</div>
			</div>
			<div class="copyright">
				@ All Rights Reserved | powered by<a
					href="https://www.beetronix.com"
					target="_blank"
				>
					BEETRONIX</a
				>
			</div>
		</footer>
	</div>
</template>

<script>
import loader from "@/components/loader.vue";
import { mapState } from "vuex";
export default {
	components: { loader },
	props: ["tabs", "loading"],
	data() {
		return {
			name: null,
			email: null,
			isSent: false,
			submitLoading: false,

			notValid: false,
		};
	},
	methods: {
		initPinterestWidget(a, b, c) {
			var d, e, f;
			(d = "PIN_" + ~~(new Date().getTime() / 864e5)),
				a[d]
					? (a[d] += 1)
					: ((a[d] = 1),
					  a.setTimeout(function () {
							(e = b.getElementsByTagName("SCRIPT")[0]),
								(f = b.createElement("SCRIPT")),
								(f.type = "text/javascript"),
								(f.async = !0),
								(f.src = c.mainUrl + "?" + Math.random()),
								e.parentNode.insertBefore(f, e);
					  }, 10));
		},
		submit() {
			if (this.$refs.form.checkValidity()) {
				this.notValid = false;
				this.submitLoading = true;
				this.$store
					.dispatch("subscription/create", {
						fullname: this.name,
						mail: this.email,
					})
					.then(() => {
						this.name = null;
						this.email = null;
						this.isSent = true;
					})
					.finally(() => {
						this.submitLoading = false;
					});
			} else {
				this.notValid = true;
			}
		},
	},

	mounted() {
		// init pinterest widget
		this.initPinterestWidget(window, document, {
			mainUrl: "https://assets.pinterest.com/js/pinit_main.js",
		});
	},
};
</script>

<style lang="scss" scoped>
.social {
	background: #eeeeee;
	display: flex;
	padding: 50px 0;
	justify-content: space-evenly;
	&-item {
		background: white;
		width: 300px;
		border-radius: 30px;
		overflow: hidden;
		&-top {
			padding: 10px 25px;
			&-text {
				font-size: 22px;
			}
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: red;
			color: white;
		}
		&-image {
			max-height: 415px;
			& img {
				background: wheat;
			}
		}
	}
}
.footer {
	max-width: 1450px;
	margin: 0 auto;
	padding: 0 20px;
	color: white;
	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid white;
		padding: 40px 0;
		&-text {
			&-description {
				line-height: 25px;
				font-size: 1rem;
			}
		}
		&-form {
			& form {
				display: flex;
			}
			&-item {
				height: 50px;
				padding: 10px 20px;
				border-radius: 25px;
				margin: 0 5px;
				font-size: 0.8rem;
			}
			&-input {
				border: 2px solid white;
				background: #0000;
				outline: none;
				color: white;
				&-error {
					border: 2px solid red;
				}
			}
			&-button {
				color: black;
				border: none;
				background: var(--blue-color);
				min-width: 120px;
			}
		}
	}
	&-bottom {
		display: flex;
		height: 160px;
		align-items: center;
		padding: 30px 0;
		&-image {
			width: 120px;
			margin-left: 10px;
			img {
				width: 100%;
			}
		}
		&-list {
			& ul {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
				align-items: center;
				list-style: none;
				padding-left: 10px;
			}
			&-item {
				margin: 5px 12px;
				font-size: 1rem;
				text-transform: capitalize;
				text-align: center;
			}
		}
		&-social {
			display: flex;
			margin-right: auto;
			&-item {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid grey;
				color: grey;
				border-radius: 50%;
				width: 30px;
				height: 30px;
				margin: 0 5px;
				* {
					font-size: 16px;
				}
			}
		}
	}
}
.copyright {
	text-align: center;
	color: #777;
	font-size: 0.5rem;
	padding: 15px 0;
	a {
		font-size: inherit;
		text-transform: uppercase;
	}
}
@media (max-width: 1200px) {
	.footer-bottom-list ul {
		grid-template-columns: repeat(4, 1fr);
	}
}
@media only screen and (max-width: 992px) {
	.social {
		flex-direction: column;
		align-items: center;
		&-item {
			margin-bottom: 20px;
		}
	}
	.footer {
		&-top {
			&-text {
				margin-bottom: 20px;
			}
			&-form {
				form {
					justify-content: end;
					flex-direction: column;
					align-items: flex-end;

					* {
						margin-bottom: 15px;
					}
					button {
						max-width: 130px;
					}
				}
			}
		}
		&-bottom {
			height: auto;
			&-list {
				ul {
					grid-template-columns: repeat(3, 1fr);
					li {
						margin: 13px 8px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.footer-top {
		display: block;
		&-form {
			form {
				align-items: stretch;
				max-width: 400px;
			}
		}
		&-text {
			margin-bottom: 40px;
		}
	}
	.footer-bottom {
		&-image {
			width: 160px;
		}
		flex-direction: column;
		align-items: flex-start;
		height: auto;
		&-list {
			ul {
				padding: 0;
			}
		}
		&-social {
			margin: 0;
		}
	}
}
@media only screen and (max-width: 576px) {
	.footer-bottom-list {
		ul {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			li {
				text-align: right;
			}
		}
	}
}
@media only screen and(max-width: 470px) {
	.footer-bottom-list ul {
		grid-template-columns: 1fr 1fr;
	}
}
</style>

<style>
span[class^="PIN"] {
	border: none !important;
}
a.eapps-link {
	display: none !important;
}
</style>