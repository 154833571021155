<template>
	<div class="my-card">
		<div class="album-title my-2">{{ data.title }}</div>
		<div class="album-video">
			<iframe
				width="100%"
				height="100%"
				:src="
					'https://www.youtube.com/embed/' + findYoutubeVideoId(data.video_link)
				"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
		<div class="album-description">
			{{
				data.description.length > 200
					? data.description.slice(0, 200) + "..."
					: data.description
			}}
		</div>
	</div>
</template>

<script>
export default {
	props: ["data"],
	methods: {
		findYoutubeVideoId(videoLink) {
			if (videoLink.indexOf("=") !== -1) {
				return videoLink.slice(videoLink.indexOf("=") + 1);
			} else {
				return videoLink.slice(videoLink.indexOf("be/") + 3);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.my-card {
}
.album {
	&-title {
		font-size: 1.2rem;
		font-family: MontserratMed;
	}
	&-video {
		height: 400px;
		@media only screen and (max-width: 765px) {
			& {
				height: 300px;
			}
		}
	}
	&-description {
	}
}
</style>