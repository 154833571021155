<template>
	<div class="page">
		<loader
			spiner
			:width="'100px'"
			v-if="!loading"
			style="margin: 100px auto; display: flex; justify-content: center"
			class="left"
		></loader>
		<div class="left" v-else>
			<breadcrumbs
				:links="[
					{ name: 'الرئيسية', path: '/' },
					{ name: 'مدوّنة', path: '/all-news', active: true },
				]"
				class="mt-3 mb-5 breadcrumbs"
			></breadcrumbs>
			<div class="main-grid mt-5">
				<article-card
					:imageHeight="'300px'"
					:product="true"
					class="main-grid-item"
					v-for="article in news"
					:key="article.id"
					:data="article"
				></article-card>
				<p
					v-if="news.length === 0"
					class="fs-3 text-center text-uppercase text-secondary my-5"
				>
					لا يوجد مقالات
				</p>
			</div>
		</div>
		<right-side></right-side>
	</div>
</template>

<script>
import ArticleCard from "@/components/ArticleCard.vue";
import RightSide from "../../components/RightSide.vue";
import { mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Loader from "@/components/loader.vue";

export default {
	components: {
		RightSide,
		ArticleCard,
		Breadcrumbs,
		Loader,
	},
	computed: {
		...mapState({
			news: (state) => state.news.news,
		}),
	},
	data() {
		return {
			colors: {
				0: "var(--red-color)",
				1: "var(--green-color)",
				2: "var(--blue-color)",
				3: "var(--yellow-color)",
			},
			cataIndex: -1,
			levelIndex: -1,
			loading: false,
		};
	},
	methods: {
		featchNews() {
			this.$store.dispatch("news/featchAll").then((data) => {
				this.loading = true;
			});
		},
	},
	mounted() {
		if (this.news.length == 0) this.featchNews();
		else this.loading = true;
	},
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
	margin-right: 300px;
	@media only screen and (max-width: 992px) {
		margin-right: 0;
	}
}
.page {
	max-width: 1450px;
	margin: auto;
	margin-top: 130px;
	padding: 0 20px;
	margin-bottom: 50px;
	display: flex;
	@media only screen and (max-width: 992px) {
		& {
			display: block;
			margin-top: 190px;
		}
	}
	.left {
		width: 66%;
		overflow: hidden;
		@media only screen and (max-width: 992px) {
			& {
				width: 100%;
			}
		}
	}
}
.main-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	> a {
		width: 18vw;
		height: calc(18vw + 150px);
		margin-bottom: 20px;
	}
	@media (min-width: 1500px) {
		> a {
			max-width: 270px;
			max-height: 420px;
		}
	}
	@media (max-width: 992px) {
		> a {
			width: 30vw;
			height: calc(30vw + 150px);
		}
	}
	@media (max-width: 768px) {
		> a {
			width: 44vw;
			height: calc(44vw + 150px);
		}
	}
	@media (max-width: 578px) {
		justify-content: center;
		> a {
			width: 85vw;
			height: calc(85vw + 150px);
		}
	}
}
.main-grid-item {
	@media only screen and(max-width:1250px) and (min-width: 992px) {
		& {
			width: 50%;
		}
	}
}
</style>