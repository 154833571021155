import * as types from '../mutations-types'

export default {
    namespaced: true,

    state: {
        sections: []
    },

    actions: {
        featchAll({ commit }) {
            return axios.get('/sections')
                .then((response) => {
                    commit(types.SECTIONS, response.data);
                    return response.data
                })
        }
    },

    mutations: {
        [types.SECTIONS](state, sections) {
            state.sections = sections
        }
    }
}